/* src/screens/PrivacyPolicy.css */
.privacy-container {
    background-color: white;
    color: black;
    padding: 20px;
    border-radius: 8px;
    margin-top: 20px;
  }
  
  .privacy-container h1,
  .privacy-container h2 {
    color: black;
  }
  
  .privacy-container ul {
    list-style-type: disc;
    margin-left: 20px;
  }
  
  .privacy-container li {
    margin-bottom: 10px;
  }
  